<template>
    <div>
        <ViewTitle :title="`客戶服務 | 通知查詢`">
            <template v-slot:buttons>
                <span v-if="mode === 'org'" class="title-tip"
                    >(提供檢視近三個月的組織通知)</span
                >
                <div class="flex mode-button">
                    <Button
                        buttonWord="個人通知"
                        buttonStyle="grey"
                        :class="mode === 'self' ? 'active' : ''"
                        @click.prevent="changeMode('self')"
                    />
                    <Button
                        buttonWord="組織通知"
                        buttonStyle="grey"
                        :class="mode === 'org' ? 'active' : ''"
                        @click.prevent="changeMode('org')"
                    />
                </div>
            </template>
        </ViewTitle>
        <TagMenu
            :options="[
                { label: '個人通知', value: 'self' },
                { label: '組織通知', value: 'org' }
            ]"
            :mode="mode"
            @change-mode="changeMode"
        />

        <NotificationFilter
            :isLoading="isTableLoading"
            :mode="mode"
            @show-data-table="showDataTable"
            v-model:filterPayloadProp="filterPayload"
        />
        <div
            class="margin-bottom-15"
            :class="mode === 'self' ? 'split-two' : 'to-right'"
        >
            <Button
                v-if="mode === 'self' && $hasPermission('completeNotice')"
                buttonWord="完成通知"
                buttonStyle="blue-hollow"
                :disabled="!hasCheckedNotice"
                @click="
                    ;(isShowConfirmModal = true),
                        $setGaEvent('clickNotificationListDone', 'click-Button')
                "
            />
        </div>
        <NotificationTable
            :listData="listData"
            :advanceFilterPayload="advanceFilterPayload"
            :isLoading="isTableLoading"
            :payload="filterPayload"
            :mode="mode"
            v-model:selected="selectedNotices"
            @get-insurance-id="showEventModal"
            @showsuppliersyncmodal="showSupplierSyncModal"
            @click-attached-icon="showAttachedModal"
        />
        <CompleteNoticeModal
            v-if="mode === 'self'"
            v-model="isShowConfirmModal"
            v-model:selected="selectedNotices"
            @golaststep="isShowModal = true"
            @updateinsservices="updateInsServices"
        />
        <InsuranceDocDownloadModal
            v-model:modelValue="isShowAttachedModal"
            title="照會附件檔"
            :docList="attachedList"
        />
        <InsuranceNoticeModal
            v-if="mode === 'self'"
            :insId="insId"
            :noticeId="noticeId"
            :manCode="this.filterPayload.manCode"
            v-model="isShowEventModel"
            @updateinsservices="updateInsServices"
        />
    </div>
    <!-- <SupplierSyncModal
        page="notification"
        :insNo="insId"
        :supplier="supplier"
        :supplierCode="supplierCode"
        v-model="isShowSupplierSyncModal"
    /> -->
</template>

<script>
// import _ from 'lodash'
import TagMenu from '@/components/TagMenu.vue'
import Button from '@/components/Button.vue'
import ViewTitle from '@/components/ViewTitle.vue'
import NotificationFilter from '@/containers/tia/NotificationFilter.vue'
import NotificationTable from '@/containers/tia/NotificationTable.vue'
import CompleteNoticeModal from '@/containers/tia/CompleteNoticeModal.vue'
import InsuranceNoticeModal from '@/containers/tia/InsuranceNoticeModal.vue'
// import SupplierSyncModal from '@/containers/tia/SupplierSyncModal.vue'
import { getTiaNotificationListAPI } from '@/assets/javascripts/api'
import InsuranceDocDownloadModal from '../../containers/tia/InsuranceDocDownloadModal.vue'

export default {
    name: 'Notification',
    components: {
        TagMenu,
        Button,
        ViewTitle,
        NotificationFilter,
        NotificationTable,
        CompleteNoticeModal,
        InsuranceNoticeModal,
        // SupplierSyncModal
        InsuranceDocDownloadModal
    },
    methods: {
        showDataTable: async function (isUpdate = false) {
            if (this.filterPayload.manCodes.length <= 0) return
            try {
                if (!isUpdate) this.isTableLoading = true
                this.$showLoading()
                let response = await this.$getResponse(
                    this.filterPayload,
                    this.permission,
                    getTiaNotificationListAPI
                )
                this.dataList = response.data
            } catch (error) {
                this.dataList = {}
            } finally {
                this.isTableLoading = false
                this.$hideLoading()
            }
        },
        showEventModal: function (data) {
            this.insId = data.insCode
            this.noticeId = data.noticeId
            this.filterPayload.manCode = data.manCode
            this.isShowEventModel = true
        },
        showAttachedModal: async function (isClick, docInfos) {
            this.isShowAttachedModal = isClick
            this.attachedList = docInfos
        },
        showSupplierSyncModal: function (val) {
            const insurance = this.listData.find((item) => item.code === val)
            this.insId = insurance.insNo
            this.supplier = insurance.supplier
            this.supplierCode = insurance.supcode
            this.isShowSupplierSyncModal = true
        },
        changeMode: function (goto) {
            const current = this.$route.params.mode
            const mode = goto || (current === 'self' ? 'org' : 'self')
            if (mode !== current) {
                this.$setGaEvent(
                    `clickGoTo${mode === 'self' ? 'Self' : 'Org'}Notice`,
                    'click-Tag'
                )
                this.$router.push({
                    path: `/tia/notification/${mode}`
                })
            }
        },
        updateInsServices: function () {
            this.showDataTable(true) //當點擊完成通知後會重打API
        }
    },
    computed: {
        listData: function () {
            return this.dataList?.insServices || []
        },
        hasCheckedNotice: function () {
            return Object.values(this.selectedNotices).some(
                (checked) => checked
            )
        }
    },
    data() {
        return {
            permission: {
                apiAlias: 'list',
                apiName: '保單列表'
            },
            isTableLoading: false,
            isShowEventModel: false,
            isShowSupplierSyncModal: false,
            isShowConfirmModal: false,
            isShowAttachedModal: false,
            attachedList: [],
            filterPayload: {
                manCode: '',
                isOrg: '',
                isChecked: false
            },
            advanceFilterPayload: {},
            dataList: {},
            insId: null,
            supplier: null,
            supplierCode: null,
            noticeId: null,
            isShowModal: this.modelValue,
            selectedNotices: {},
            mode: this.$route.params.mode
        }
    }
}
</script>

<style lang="scss" scoped>
.mode-button {
    margin-bottom: -35px;
    .button {
        background-color: $forth-white;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        margin-right: 0px;
        &.active {
            position: relative;
            background-color: $primary-white;
            border-color: $primary-white;
            box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.3);
            cursor: auto;
            &:last-of-type {
                box-shadow: -2px 1px 3px rgba(0, 0, 0, 0.3);
            }
        }
        &:not(.active) {
            :deep(.button-word) {
                opacity: 0.5;
            }
            &:hover {
                :deep(.button-word) {
                    opacity: 1;
                }
            }
        }
    }
    @media screen and(max-width:577px) {
        display: none;
    }
}

:deep(.scroll-wrapper) {
    display: none;
    @media screen and(max-width:577px) {
        display: flex;
    }
}
:deep(.main-header-container) {
    position: relative;
}

.title-tip {
    font-size: 14px;
    position: absolute;
    left: 215px;
    top: 10px;
    color: $secondary-grey;
    @media (min-width: 577px) and (max-width: 680px) {
        top: 0;
        width: calc(100% - 410px);
    }
    @media screen and (max-width: 576px) {
        left: 0px;
        top: auto;
        bottom: 0px;
    }
}
:deep(.download-link-icon),
:deep(.download-content-icon) {
    display: block;
    max-width: 7.5em;
    height: 20px;
    padding-left: 20px;
    margin-right: 5px;
    background-position: left;
    text-align: left;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    cursor: pointer;
}
:deep(.download-link-icon) {
    background-size: 25px 25px;
    background-position: -2px -2px;
}
</style>
