<template>
    <div class="main-header-container split-two">
        <div class="main-header-word">{{ title }}</div>
        <slot name="buttons" />
    </div>
</template>

<script>
export default {
    name: 'ViewTitle',
    props: {
        title: {
            type: String
        }
    }
}
</script>

<style lang="scss" scoped>
.main-header-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 0px 20px 0px;
}
.main-header-word {
    color: $sixth-black;
    font-size: 24px;
    line-height: 33px;

    @media screen and (max-width: 350px) {
        font-size: 22px;
    }
}
</style>
