<template>
    <div class="scroll-wrapper" ref="scroll">
        <div
            :class="{ 'menu-container': !isDashboard, dashboard: isDashboard }"
            ref="itemContainer"
        >
            <div
                v-for="(option, index) in options"
                ref="menu"
                :key="index"
                v-text="option.label"
                class="menu-item"
                :class="{
                    active: modelValue === option.value || mode === option.value
                }"
                @click="changeMode(option.value, $event)"
            />
        </div>
    </div>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill'

export default {
    name: 'TagMenu',
    emits: ['change-mode', 'update:modelValue'],
    props: {
        mode: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        options: {
            type: Array,
            default: function () {
                return [
                    { label: 'label_1', value: 'value1' },
                    { label: 'label_2', value: 'value2' }
                ]
            }
        },
        isDashboard: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        changeMode: function (val, event) {
            this.$emit('change-mode', val)
            this.$emit('update:modelValue', val)

            this.$nextTick(() => {
                smoothscroll.polyfill()
                const item = event.target
                const wrapper = this.$refs.scroll
                const container = this.$refs.itemContainer
                const width = container.offsetWidth
                const offsetPosition = item.offsetLeft
                let move = 0

                move = offsetPosition - item.offsetWidth / 2
                if (move < item.offsetWidth) move = 0
                else if (width - move < item.offsetWidth) move = width
                else move = offsetPosition

                wrapper.scrollTo({
                    left: move,
                    behavior: 'smooth'
                })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.scroll-wrapper {
    min-width: calc(100% - 20px);
    overflow-x: auto;
    &::-webkit-scrollbar {
        display: none;
    }
}

.menu-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 16px 0 6px 0;
    margin: 0 0 30px 0px;
    box-sizing: border-box;
    border-bottom: 1px rgba(151, 151, 151, 0.4) solid;
    z-index: 1;
    min-width: max-content;
    :not(:last-child) {
        margin-right: 20px;
    }

    .menu-item {
        text-align: center;
        position: relative;
        width: 100%;
        font-size: 14px;
        color: $sixth-black;
        cursor: pointer;
        word-break: keep-all;
        &:after {
            content: '';
            display: block;
            height: 3px;
            width: 40px;
            position: absolute;
            top: calc(100% + 5px);
            left: 50%;
            transform: translateX(-50%);
            margin-bottom: 5px;
        }

        &.active:after {
            background-color: $primary-red;
        }
    }
}
.dashboard {
    display: flex;
    min-width: max-content;
    justify-content: center;
    :not(:last-child) {
        margin-right: 45px;
    }

    @media screen and (max-width: 576px) {
        :not(:last-child) {
            margin-right: 25px;
        }
    }

    .menu-item {
        display: flex;
        align-items: center;
        text-align: center;
        position: relative;
        font-size: 15px;
        color: $secondary-grey;
        min-height: 40px;
        cursor: pointer;
        word-break: keep-all;
        &.active {
            color: $sixth-black;
            font-weight: 500;
            font-size: 15px;
        }

        &.active:after {
            content: '';
            display: block;
            height: 3px;
            width: 40px;
            position: absolute;
            bottom: 0;
            top: calc(100% - 1.5px);
            left: 50%;
            transform: translate(-50%, -50%);
            margin-bottom: 5px;
            background-color: $fourth-blue;
        }
    }
}
</style>
